// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import TopNav from '../../components/src/TopNav';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ContentFlag from '../../blocks/ContentFlag/src/ContentFlag';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import Favourites from '../../blocks/favourites/src/Favourites';
import AddFavourites from '../../blocks/favourites/src/AddFavourites';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import OrderCreation from '../../blocks/OrderCreation/src/OrderCreation';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import Geofence from '../../blocks/geofence/src/Geofence';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import StripeIntegration from '../../blocks/stripepayments/src/StripeIntegration';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import Analytics from '../../blocks/analytics/src/Analytics';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import InventoryManagement2 from '../../blocks/InventoryManagement2/src/InventoryManagement2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import Location from '../../blocks/location/src/Location';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Surveys from '../../blocks/Surveys/src/Surveys';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import ApplePayIntegration from '../../blocks/ApplePayIntegration/src/ApplePayIntegration';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import Chat from '../../blocks/chat/src/Chat';
import ChatView from '../../blocks/chat/src/ChatView';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import MailChimp from '../../blocks/MailChimp/src/Mailchimp.web';
const routeMap = {
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  ContentFlag: {
    component: ContentFlag,
    path: '/ContentFlag'
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders'
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  AddFavourites: {
    component: AddFavourites,
    path: '/AddFavourites'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  OrderCreation: {
    component: OrderCreation,
    path: '/OrderCreation'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  Geofence: {
    component: Geofence,
    path: '/Geofence'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  InventoryManagement2: {
    component: InventoryManagement2,
    path: '/InventoryManagement2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  ApplePayIntegration: {
    component: ApplePayIntegration,
    path: '/ApplePayIntegration'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatView: {
    component: ChatView,
    path: '/ChatView'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  MailChimp: {
    component: MailChimp,
    path: '/MailChimp'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
